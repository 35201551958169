import React, { useState } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
ReactSession.setStoreType("localStorage");

function Addvendor() {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [state, setState] = useState('');
    const [password, setPassword] = useState('');
    const [pincode, sePincode] = useState('');
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');

    const handleinputname = (event) => {
        setName(event.target.value);
      }

      const handleinputemail = (event) => {
        setEmail(event.target.value);
      }

      const handleinputaddress = (event) => {
        setAddress(event.target.value);
      }

      const handleinputphone = (event) => {
        setPhone(event.target.value);
      }


      const handleinputstate = (event) => {
        setState(event.target.value);
      }


      const handleinputpincode = (event) => {
        sePincode(event.target.value);
      }
      const handleinputpass = (event) => {
        setPassword(event.target.value);
      }


  const handleFile = (event) => {
    setFile(event.target.files[0]); // Only taking the first file if multiple files are selected
  };

const handleSubmit = async (event) => {
    event.preventDefault();
   
    const formData = new FormData();

    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('address', address);
    formData.append('state', state);
    formData.append('pincode', pincode);
    formData.append('image', file);
    formData.append('pass', password);
    
    console.log(formData);

   // openModal();
    try {
       
        const response = await axios.post('https://test.arivomkadai.com/api/addvendor/', formData);
        //console.log("Response data:", response.data);
        // Redirecting to home page
        navigate('/dashboard');
    } catch (error) {
        // Handle error
        console.error('There was a problem with login:', error);
        setError('Invalid email or password');
    }
   // closeModal();
};

  return (
    <>
    <div id="onloadModal" >
    <div class="modal-content"  align='center'>
        <p style={{padding:'20px'}}>Product Loading.</p>
    </div>
     </div>
    <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
            <Aside/>
            <div className="layout-page">
                <Navbar/>
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-4">
                                    <h5 className="card-header">Add New Vendor</h5>
                                    <div className="card-body">
                                        <form id="formAccountSettings"  onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="mb-3 col-md-4">
                                                    <label htmlFor="link" className="form-label">Name</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        value={name}
                                                        onChange={handleinputname}
                                                    />
                                                    
                                                </div>

                                                <div className="mb-3 col-md-4">
                                                    <label htmlFor="link" className="form-label">Phone Number</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="phone"
                                                        name="phone"
                                                        value={phone}
                                                        onChange={handleinputphone}
                                                    />
                                                    
                                                </div>


                                                <div className="mb-3 col-md-4">
                                                    <label htmlFor="link" className="form-label">Email</label>
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        value={email}
                                                        onChange={handleinputemail}
                                                      
                                                    />
                                                    
                                                </div>

                                               
                                                <div className="mb-3 col-md-3">
                                                    <label htmlFor="des" className="form-label">Password</label>
                                                    <input
                                                        className="form-control"
                                                        type="State"
                                                        name="password"
                                                        id="classic-editor"
                                                        value={password}
                                                        onChange={handleinputpass}
                                                        
                                                    />
                                                </div>
                                                
                                                
                                                

                                                <div className="mb-3 col-md-3">
                                                    <label htmlFor="des" className="form-label">State</label>
                                                    <input
                                                        className="form-control"
                                                        type="State"
                                                        name="State"
                                                        id="classic-editor"
                                                        value={state}
                                                        onChange={handleinputstate}
                                                        
                                                    />
                                                </div>


                                                <div className="mb-3 col-md-3">
                                                    <label htmlFor="des" className="form-label">Pincode</label>
                                                    <input
                                                        className="form-control"
                                                        type="pincode"
                                                        name="pincode"
                                                        id="classic-editor"
                                                        value={pincode}
                                                        onChange={handleinputpincode}
                                                        
                                                    />
                                                </div>


                                                <div className="mb-3 col-md-3">
                                                    <label htmlFor="image" className="form-label">Image</label>
                                                    <input
                                                      className="form-control"
                                                      type="file"
                                                      name="image"
                                                      id="image"
                                                      accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                      onChange={handleFile}
                                                    />
                                                 </div>
                                                
                                                 <div className="mb-3 col-md-12">
                                                    <label htmlFor="des" className="form-label">Address</label>
                                                    <textarea
                                                        className="form-control"
                                                        type="text"
                                                        name="address"
                                                        id="address"
                                                        value={address}
                                                        onChange={handleinputaddress}
                                                    />
                                                </div>
                                                
                                                
                                                
                                                
                                            </div>
                                            <div className="mt-2" align="center">
                                                <button type="submit" className="btn btn-primary me-2 w-25">Add Vendor</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                    <div className="content-backdrop fade"></div>
                </div>
            </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
    </div>
    </>
  )
}
export default  Addvendor;
