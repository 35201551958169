import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");



 function Onlineorder() {
    const userID = ReactSession.get('userId');
    const [first, setFirst] = useState(0); // Index of the first row on the current page
    const [rows, setRows] = useState(15);
  const [order, setOrder] = useState({});
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(order.data);
  
  // Function to handle search input change
  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  
    // Filter datas.data based on search query
    const filtered = order.data.filter(item =>
      item.product.toLowerCase().includes(query.toLowerCase()) ||
      item.device.toLowerCase().includes(query.toLowerCase()) ||
      item.date.toLowerCase().includes(query.toLowerCase()) ||
	   item.ip.toLowerCase().includes(query.toLowerCase()) ||
     item.id.toLowerCase().includes(query.toLowerCase())  	   
    );
    setFilteredData(filtered);
  };

  if(userID <= 0 ){
    navigate('/login');
 }

  const fetchData = async () => {
    try {
      const response = await axios.post('https://test.arivomkadai.com/api/onlineorders/');
      setOrder(response.data);
      console.log("orders:", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(15); // Update rows to display only one row per page
  };
  return (
    <div className="layout-wrapper layout-content-navbar">
    <div className="layout-container">
      <Aside />
      <div className="layout-page">
        <Navbar />
        <div className="content-wrapper">
    
        <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row">
        <div class="col-lg-8 mb-4 order-0">
                <div class="card">
                  <div class="d-flex align-items-end row">
                    <div class="col-sm-7">
                      <div class="card-body">
                        <h5 class="card-title text-primary p-2">Products</h5>
                        <p class="mb-4">
                          <span class="fw-bold">Arivomkadai:</span> Bridging the Gap Between Online and Offline Excellence products.
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-5 text-center text-sm-left">
                      <div class="card-body pb-0 px-0 px-md-4">
                        <img
                          src="../assets/img/illustrations/man-with-laptop-light.png"
                          height="140"
                          alt="View Badge User"
                          data-app-dark-img="illustrations/man-with-laptop-dark.png"
                          data-app-light-img="illustrations/man-with-laptop-light.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="col-md-6 col-lg-4 col-xl-4 order-0 mb-4">
                <div class="card pb-4">
                  <div class="card-header d-flex align-items-center justify-content-between pb-0">
                    <div class="card-title mb-0">
                      <h5 class="m-0 me-2 mb-2">Online Orders</h5>
                      <span>Total Orders</span>
                    </div>
                    <div class="avatar flex-shrink-0">
                            <img src="../assets/img/icons/unicons/cc-primary.png" alt="Credit Card" class="rounded" />
                          </div>
                    
                  </div>
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex flex-column align-items-center gap-1">
                        <h2 class="mb-2">{order && order.count}</h2>
                      </div></div>
                  </div>
                </div>
              </div>
              </div>


              </div>

            

              <div className="container-xxl flex-grow-1 container-p-y ">
            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span> Online orders</h4>
            <ul>
       
           <div className="card">
          <h5 className="card-header">online Orders</h5>
          <div className="demo-inline-spacing" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-50px' }}>
          <div className="col-lg-3 ml-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search by ip or product name or date or device..."
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
            </div>
         
          <div className="table-responsive text-nowrap mt-2">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>ip</th>
                  <th>Items</th>
                  <th>device</th>
                  <th>date</th>
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
              {searchQuery && filteredData.length > 0 ? (
            <>  {filteredData.map(item => (
                <tr key={item.id}>
                   
                <td>{item.ip}</td>
                <td>{item.product}</td>
                <td><span className="badge bg-label-primary me-1 text-wrap" style={{ display: 'inline-block', lineHeight: 1.5 }}>{item.device}</span></td>
                <td><span className="badge bg-label-primary me-1 text-wrap" style={{ display: 'inline-block', lineHeight: 1.5 }}>{item.date}</span></td>
               </tr>))} </>
             ) : searchQuery && filteredData.length === 0 ? (
            <tr >
                         <td>No products found </td>
                       </tr>
         ) : (
            <> 
                {order && order.data && order.data.reverse().slice(first, first + rows).map(item => (
                  <tr key={item.id}>
                   
                    <td>{item.ip}</td>
                    <td>{item.product}</td>
                    <td><span className="badge bg-label-primary me-1 text-wrap" style={{ display: 'inline-block', lineHeight: 1.5 }}>{item.device}</span></td>
                    <td><span className="badge bg-label-primary me-1 text-wrap" style={{ display: 'inline-block', lineHeight: 1.5 }}>{item.date}</span></td>
                   </tr>
                ))}
                </>)}
              </tbody>
            </table>
            <Paginator first={first} rows={rows} totalRecords={order.data ? order.data.length : 0}  onPageChange={onPageChange} />
             
             </div>
        </div>
      </ul>
          </div>


          
          <Footer />
          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </div>
    <div className="layout-overlay layout-menu-toggle"></div>
  </div>
  )
}
export default Onlineorder;
