import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

function Customer() {
    const userID = ReactSession.get('userId');
    const navigate = useNavigate();
    const [first, setFirst] = useState(0); // Index of the first row on the current page
    const [rows, setRows] = useState(10);
  const [order, setOrder] = useState({});
   const [searchQuery, setSearchQuery] = useState('');
const [filteredData, setFilteredData] = useState(order.data);

  if(userID <= 0 ){
    navigate('/login');
 }
 const handleSearchInputChange = (event) => {
  const query = event.target.value;
  setSearchQuery(query);

  // Filter datas.data based on search query
  const filtered = order.data.filter(item =>
    item.name.toLowerCase().includes(query.toLowerCase()) ||
    item.phonenumber.toLowerCase().includes(query.toLowerCase())
  );
  setFilteredData(filtered);
};

  const fetchData = async () => {
    try {
      const response = await axios.post('https://test.arivomkadai.com/api/offlinecustomer/');
      setOrder(response.data);
      //console.log("orders:", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(10); // Update rows to display only one row per page
  };
  return (
    <div className="layout-wrapper layout-content-navbar">
    <div className="layout-container">
      <Aside />
      <div className="layout-page">
        <Navbar />
        <div className="content-wrapper">
    
       

            

              <div className="container-xxl flex-grow-1 container-p-y ">
           
       
           <div className="card">
          <h5 className="card-header">Arivomkadai Customers</h5>
		  
		   <div className="demo-inline-spacing" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-50px' }}>
          <div className="col-lg-3 ml-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Customer name or Phonenumber..."
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
            </div>
         
          <div className="table-responsive text-nowrap mt-2">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Gender</th>
                  <th>Email</th>
                  <th>Phone</th>
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
			  {searchQuery && filteredData.length > 0 ? (
            <>  {filteredData.map(customer => (

             <tr key={customer.user_id}>
                   
                   <td>


                      
                      {customer.profile ? (
                        <img src={`https://test.arivomkadai.com/api/customer_data/${customer.profile}`} alt={customer.name} className="w-px-40 h-px-40 rounded-circle" />
                      ) : (
                        <img  src={'https://test.arivomkadai.com/api/customer_data/no_image.webp'}  alt="No Image" className="w-px-40 h-px-40 rounded-circle" />
                      )}
                       <span class="fw-medium ml-1">{customer.name}</span>
                    </td>
                    <td>{customer.gender}</td>
                    <td>{customer.email}</td>
                    <td>{customer.phonenumber}</td>
                   </tr>
                ))} </>
              ) : searchQuery && filteredData.length === 0 ? (
            <tr >
                         <td>No products found </td>
                       </tr>
         ) : (<>
                {order && order.data && order.data.slice(first, first + rows).reverse().map(customer => (
                  <tr key={customer.user_id}>
                   
                   <td>


                      
                      {customer.profile ? (
                        <img src={`https://test.arivomkadai.com/api/customer_data/${customer.profile}`} alt={customer.name} className="w-px-40 h-px-40 rounded-circle" />
                      ) : (
                        <img  src={'https://test.arivomkadai.com/api/customer_data/no_image.webp'}  alt="No Image" className="w-px-40 h-px-40 rounded-circle" />
                      )}
                       <span class="fw-medium ml-1">{customer.name}</span>
                    </td>
                    <td>{customer.gender}</td>
                    <td>{customer.email}</td>
                    <td>{customer.phonenumber}</td>
                   </tr>
                ))}
				 </>)}
              </tbody>
            </table>
            <Paginator first={first} rows={rows} totalRecords={order.data ? order.data.length : 0}  onPageChange={onPageChange} />
             
             </div>
        </div>
     
          </div>


          
          <Footer />
          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </div>
    <div className="layout-overlay layout-menu-toggle"></div>
  </div>
  )
}
export default Customer;
