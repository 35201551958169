import React, { useState } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
ReactSession.setStoreType("localStorage");


function Addproduct() {
    const userID = ReactSession.get('userId');
	const [code, setCode] = useState("");
    const [name, setName] = useState("");
    const [cat, setCat] = useState("");
    const [price, setPrice] = useState("");
    const [rating, setRating] = useState("");
	   const [review, setReview] = useState("");
    const [keyword, setKeyword] = useState("");
    const [link, setLink] = useState("");
    const [links, setLinks] = useState(""); // Changed setInput to setLink for clarity
    const [file, setFile] = useState(null); 
    const [file1, setFile1] = useState(null); 
    const [file2, setFile2] = useState(null); 
    const [file3, setFile3] = useState(null); 
    const [video, setVideo] = useState(null); // Changed empty string to null for file state
    const [des, setDes] = useState("");
    const navigate = useNavigate();
    const [error, setError] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [productCode, setProductCode] = useState(true);
	const [selectedVideo, setselectVideo] = useState('video');
	
	
    const selectVideo =() =>{
		if(selectedVideo === 'video'){
			setselectVideo('url');
		}else{
			setselectVideo('video');
		}
	};
     
    if(userID <= 0 ){
        navigate('/login');
     }

       
 const openModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
        document.body.classList.add('modal-open');
    }
  };
  
  const closeModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
    }
  };

   const handleCode = async (event) => {
    const code = event.target.value;
    setCode(code); 
    
    if(code === '' || code === null){
	  setErrorMessage('Please enter a valid Product code');
	}else{
		
        const response = await axios.put('https://test.arivomkadai.com/api/addproduct/', { proCode: code });
		const results = response.data;
        const result = response.data.status;
		// console.log(results);
      // console.log(result);
		if(result === 'error'){
			const errorMessage = response.data.nextId;
            setErrorMessage(errorMessage);
		}else{
			setErrorMessage('');
		} 
	}
};

	
		const getProductID =async()=>{

		const response = await axios.get('https://test.arivomkadai.com/api/addproduct/');
		const results = response.data;
        const result = response.data.nextId;
		setCode('O00'+result);
		//console.log(result);
		};
		
		
		
		if(productCode === true) {
		  getProductID();
		  setProductCode(false);
	    }
	




    const handleName = (event) => {
        setName(event.target.value); // Changed setInput to setLink
    };
	 const handleCat = (event) => {
        setCat(event.target.value); // Changed setInput to setLink
    };


    const handlePrice = (event) => {
        setPrice(event.target.value); // Changed setInput to setLink
    };

    const handleRating = (event) => {
        setRating(event.target.value); // Changed setInput to setLink
    };

    const handleReview = (event) => {
        setReview(event.target.value); // Changed setInput to setLink
    };
	const handleKeyword = (event) => {
        setKeyword(event.target.value); // Changed setInput to setLink
    };

    const handleVideo = (event) => {
        setVideo(event.target.files[0]); // Changed setInput to setLink
    };

    const handleLink = (event) => {
        setLink(event.target.value); // Changed setInput to setLink
    };
    
    const handleLink1 = (event) => {
        setLinks(event.target.value); // Changed setInput to setLink
    };
	 const handleUrl = (event) => {
        setVideo(event.target.value); // Changed setInput to setLink
    };


    const handleDes = (event) => {
        setDes(event.target.value);
		if (typeof window !== 'undefined') {
    ClassicEditor
      .create(document.querySelector('#classic-editor'), {
        // CKEditor configuration here
      })
      .then(editor => {
        editor.model.document.on('change:data', () => {
          const data = editor.getData();
          setDes(data);
        });
      })
      .catch(error => {
        console.error('Error initializing CKEditor:', error);
      });
  }
    };
    
    const handleFile = (event) => {
        setFile(event.target.files[0]); // Only taking the first file if multiple files are selected
    };

    const handleFile1 = (event) => {
        setFile1(event.target.files[0]); // Only taking the first file if multiple files are selected
    };

    const handleFile2 = (event) => {
        setFile2(event.target.files[0]); // Only taking the first file if multiple files are selected
    };
    const handleFile3 = (event) => {
        setFile3(event.target.files[0]); // Only taking the first file if multiple files are selected
    };
    
    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('link', link);
        formData.append('links', links);
        formData.append('content', des);
        formData.append('image', file);
        formData.append('image1', file1);
        formData.append('image2', file2);
        formData.append('image3', file3);
        formData.append('name', name);
        formData.append('cat', cat);
        formData.append('price', price);
        formData.append('rating', rating);
        formData.append('review', review);
        formData.append('video', video);
        formData.append('code', code);
		formData.append('keyword', keyword);
		
        
        openModal();
        try {
           
            const response = await axios.post('https://test.arivomkadai.com/api/addproduct/', formData);
            //console.log("Response data:", response.data);
            // Redirecting to home page
      navigate('/online');
        } catch (error) {
            // Handle error
            console.error('There was a problem with login:', error);
            setError('Invalid email or password');
        }
        closeModal();
    };

    return (
        <>
        <div id="onloadModal" >
        <div class="modal-content"  align='center'>
            <p style={{padding:'20px'}}>Product Loading.</p>
        </div>
         </div>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Aside/>
                <div className="layout-page">
                    <Navbar/>
                    <div className="content-wrapper">

                 



                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span> Add Product</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        <h5 className="card-header">Add New Product</h5>
                                        <div className="card-body">
                                            <form id="formAccountSettings"  onSubmit={handleSubmit}>
                                                <div className="row">
												    <div className="mb-3 col-md-3">
                                                        <label htmlFor="link" className="form-label">Product Code</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="code"
                                                            name="code"
                                                            value={code}
                                                            onChange={handleCode}
                                                        />
														 {errorMessage && errorMessage.length > 0 && (
                                              <p className="text-danger">{errorMessage}</p>
                                            )}
                                                    </div>
                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="link" className="form-label">Name</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            value={name}
                                                            onChange={handleName}
                                                        />
                                                    </div>
													<div className="mb-3 col-md-3">
                                                        <label htmlFor="des" className="form-label">Category</label>
                                                       <select
                                                            className="form-control"
                                                            type="text"
                                                            name="cat"
                                                            id="cat"
                                                            value={cat}
                                                            onChange={handleCat}
                                                        >
                                                        <option value="">Select Category</option>
														 <option value="Tech">Tech</option>
														 <option value="Home">Home</option>
														 <option value="Kitchen">Kitchen</option>
														 <option value="Kids">Kids</option>
														 <option value="Gifts">Gifts</option>
														 <option value="Personalcare">Personalcare</option>
														 <option value="Health">Health</option>
														 <option value="Automobile">Automobile</option>
														 <option value="Fashions">Fashions</option>
														 </select>
                                                    </div>
                                                  
                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="link" className="form-label">Buy Link</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="link"
                                                            name="link"
                                                            value={link}
                                                            onChange={handleLink}
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="link" className="form-label">Post Link</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="link"
                                                            name="links"
                                                            value={links}
                                                            onChange={handleLink1}
                                                        />
                                                    </div>
                                                   

                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="link" className="form-label">Price</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="price"
                                                            name="price"
                                                            value={price}
                                                            onChange={handlePrice}
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="link" className="form-label">Review</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="review"
                                                            name="review"
                                                            value={review}
                                                            onChange={handleReview}
                                                        />
                                                    </div>
                                                  
                                                    <div className="mb-3 col-md-3">
    <label htmlFor="review" className="form-label">Rating</label>
    <select
                className="form-select"
                id="review"
                name="rating"
                value={rating}
                onChange={handleRating}
            >
                
                <option value="">Select Rating</option>
                <option value="3">★★★☆☆ (3 stars)</option>
				<option value="3.0">★★★☆☆ (3.0 stars)</option>
                <option value="3.1">★★★☆☆ (3.1 stars)</option>
                <option value="3.2">★★★☆☆(3.2 stars)</option>
                <option value="3.3">★★★☆☆ (3.3 stars)</option>
                <option value="3.4">★★★☆☆ (3.4 stars)</option>
                <option value="3.5">★★★½☆ (3.5 stars)</option>
                <option value="3.6">★★★½☆ (3.6 stars)</option>
                <option value="3.7">★★★½☆ (3.7 stars)</option>
                <option value="3.8">★★★½☆(3.8 stars)</option>
                <option value="3.9">★★★½☆ (3.9 stars)</option>
                <option value="4">★★★★☆ (4 stars)</option>
				<option value="4.0">★★★★☆ (4.0 stars)</option>
                <option value="4.1">★★★★☆ (4.1 stars)</option>
                <option value="4.2">★★★★☆ (4.2 stars)</option>
                <option value="4.3">★★★★☆ (4.3 stars)</option>
                <option value="4.4">★★★★☆ (4.4 stars)</option>
                <option value="4.5">★★★★½ (4.5 stars)</option>
                <option value="4.6">★★★★½ (4.6 stars)</option>
                <option value="4.7">★★★★½(4.7 stars)</option>
                <option value="4.8">★★★★½ (4.8 stars)</option>
                <option value="4.9">★★★★½ (4.9 stars)</option>
                <option value="5.0">★★★★★ (5.0 stars)</option>
               

            </select>
    </div>
                                                   
                                                    <div className="mb-3 col-md-6">
                                                        <label htmlFor="image" className="form-label">Image</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="image"
                                                            id="image"
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            onChange={handleFile}
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-md-6">
                                                        <label htmlFor="image" className="form-label">Image</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="image1"
                                                            id="image1"
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            onChange={handleFile1}
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-md-6">
                                                        <label htmlFor="image" className="form-label">Image</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="image2"
                                                            id="image2"
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            onChange={handleFile2}
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-md-6">
                                                        <label htmlFor="image" className="form-label">Image</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="image3"
                                                            id="image3"
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            onChange={handleFile3}
                                                        />
                                                    </div>
													
													
													<div className="mb-3 mt-4 col-md-4">
													   <button type="button"  className={selectedVideo === 'video' ? 'btn btn-primary me-2' : 'btn btn-secondary me-2'} onClick={selectVideo}>Video File</button>
													   <button type="button" className={selectedVideo === 'video' ? 'btn btn-secondary me-2' : 'btn btn-primary me-2'} onClick={selectVideo}>YouTube Video</button>
                                                    </div>
													
													{selectedVideo === 'video' ? (
                                                    <div className="mb-3 col-md-8">
                                                        <label htmlFor="des" className="form-label">Video</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="video"
                                                            id="video"
                                                            accept="mp4"
                                                            onChange={handleVideo}
                                                        />
                                                    </div>
													
													):(
													
													<div className="mb-3 col-md-8">
                                                        <label htmlFor="des" className="form-label">Video Url</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="url"
                                                            id="url"
															 value={video}
                                                            onChange={handleUrl}
                                                        />
                                                    </div>
													
													)}
													
													 <div className="mb-3 col-md-12">
                                                        <label htmlFor="des" className="form-label">Description</label>
                                                        <textarea
                                                            className="form-control"
                                                            type="text"
                                                            name="des"
                                                            id="classic-editor"
                                                            value={des}
                                                            onChange={handleDes}
                                                        />
                                                    </div>
													
													
													{/* <div className="mb-3 col-md-12">
                                                        <label htmlFor="des" className="form-label">Keywords</label>
                                                        <textarea
                                                            className="form-control"
                                                            type="text"
                                                            name="keyword"
                                                            id="keyword"
                                                            value={keyword}
                                                            onChange={handleKeyword}
                                                        />
                                                    </div> */}
													
													
                                                </div>
                                                <div className="mt-2">
                                                    <button type="submit" className="btn btn-primary me-2">Add</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
        </div>
        </>
    );
}

export default Addproduct;
