import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../App.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

 function Invoice() {
    const userID = ReactSession.get('userId');
    const [order, setOrder]=useState({});
    const [preorder, setpreOrder]=useState({});
    const [process, setProcess] = useState([]);
    const [preorderprocess, setpreorderProcess] = useState([]);
    const queryParameters = new URLSearchParams(window.location.search)
    const orderId = queryParameters.get("id");
    //console.log(orderId);
    const navigate = useNavigate();

    if(userID <= 0 ){
        navigate('/login');
     }


     const fetchData = async () => {
   
      // console.log("user:",formData);
     try {
      const formData = {id:orderId};
       const response = await axios.post('https://test.arivomkadai.com/api/processsucess/' , formData);
       setProcess(response.data);
     //  console.log("orders:", response.data);
     } catch (error) {
       
       console.error("Error fetching data:", error);
     }
   };
   useEffect(() => {
   fetchData();
   }, []);


   const fetchpreorderData = async () => {
      
    // console.log("user:",formData);
   try {
     const formData = {id:orderId};
     const response = await axios.post(`https://test.arivomkadai.com/api/proordersucess/`, formData);

     setpreorderProcess(response.data);
    // console.log(response.data);
   } catch (error) {
     
     console.error("Error fetching data:", error);
   }
 };
 useEffect(() => {
    fetchpreorderData();
 
 
 }, []); 

    
 
 

 const fetchDatas = async () => {
   
    // console.log("user:",formData);
   try {
     const response = await axios.get('https://test.arivomkadai.com/api/offlineorders/');
     setOrder(response.data);
     console.log("orders:", response.data);
   } catch (error) {
     
     console.error("Error fetching data:", error);
   }
 };
 useEffect(() => {
 fetchDatas();
 }, []);

 const fetchpreDatas = async () => {
   
  // console.log("user:",formData);
 try {
   const response = await axios.get('https://test.arivomkadai.com/api/preorder_orders/');
   setpreOrder(response.data);
   //console.log("orders:", response.data);
 } catch (error) {
   
   console.error("Error fetching data:", error);
 }
};
useEffect(() => {
  fetchpreDatas();
}, []);

 const order_ids = order && order.data && order.data.filter(item => item.order_id === orderId); // Filter product data based on the category ID

 //console.log("Filtered orders:", order_ids);



 const preorder_ids = preorder && preorder.data && preorder.data.filter(item => item.order_id === orderId); // Filter product data based on the category ID

 //console.log("Filtered orders:", preorder_ids);



 const openModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
  }
};

const closeModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
  }
};

 const handleDownloadPDF = async () => {
   openModal();
  const input = document.getElementById('pdf-content');
  input.classList.remove('hide');

  const pdf = new jsPDF({
    orientation: 'portrait', // Use 'portrait' or 'landscape' as per your requirement
    unit: 'pt', // Set unit to millimeters
    format: 'a4', // Set format to A4
  });
  const inputHeight = input.clientHeight;
  const pageHeight = pdf.internal.pageSize.getHeight()+600;
  let currentPosition = 0;
  const scaleFactor = 4; 
  
  // Increase this value for higher resolution and better quality

  while (currentPosition < inputHeight) {
    const canvas = await html2canvas(input, {
      height: Math.min(pageHeight),
      
      y: currentPosition,
      scrollY: -currentPosition,
      scale: scaleFactor
    });

    

    
    const imgData = canvas.toDataURL('image/png', 4);
    pdf.addImage(imgData, 'PNG', 0, 0, 580, pdf.internal.pageSize.getHeight(), '', 'FAST');
    //currentPosition +=canvas.height;
    currentPosition += pageHeight;
    if (currentPosition < inputHeight) {
      pdf.addPage();
    }
   
  }
 pdf.save(orderId);
  
  input.classList.add('hide');
   closeModal();
};




 let gst = 0;    
 let price = 0;    
 let subTotal = 0;    

 preorderprocess && preorderprocess.data && preorderprocess.data.preorder === 'preorder' ?
 preorderprocess && preorderprocess.data && preorderprocess.data.orderdetails && preorderprocess.data.orderdetails.forEach(product => {
    
    price += Number(product.price*product.quantity);
 })

 : process && process.data && process.data.orderdetails && process.data.orderdetails.forEach(product => {
  
   price += Number(product.price*product.quantity);
   gst += Number(((product.price-(product.price/(1+product.gst/100)))*product.quantity));
 })

 gst = (gst).toFixed(2);
 subTotal = (price-gst).toFixed(2);

  return (
    <>
    
    <div id="onloadModal" >
    <div class="modal-content"  align='center'>
        <p style={{padding:'20px'}}>Loading.</p>
    </div>
     </div>
    <div className="layout-wrapper layout-content-navbar" >
    <div className="layout-container">
      <Aside />
      <div className="layout-page">
        <Navbar />
      
        <div className="content-wrapper">
        <div class="invoice-btn-section clearfix d-print-none">
                        {/* <a href="javascript:window.print()" class="btn btn-lg btn-print">
                            <i class="fa fa-print"></i> Print Invoice
                        </a> */}
                        <button onClick={handleDownloadPDF} className="btn btn-primary me-2 mt-2 ml-5"  style={{float:'right'}}> <i class="fa fa-download"></i> Download Invoice</button>
                                              
                        
                    </div>
    
        <div id="pdf-content" style={{minWidth: '1000px', maxWidth: '1000px', margin:'0px'}}>
            
            

<div class="row invoice-preview">

  <div class="col-xl-12 col-md-8 col-12 mb-md-0 mb-4">
    
  <div style={{ display: 'flex'}}>
        <div  style={{ minWidth: '9%',  minHeight: '100%', background: '#ffc107', }}></div>
        <div style={{minWidth: '87%', maxWidth: '91%', marginLeft: 'auto', marginRight: 'auto', marginTop: '50px'}}>
        <table style={{borderCollapse: 'collapse', top: '50px', width:'100%'}}>
    
    <tr>
        <td colspan="3" style={{border:'0px',  textAign:'left', fontsize:'12px'}}>
        <img src="logo.png" alt="logo"/>
      </td>
        <td width="50%" colspan="3" style={{border:'0px', textAlign:'right', fontSize:'15px', paddingbottom:'20px'}}>
        5D, R.P jaya paradise<br></br>Chitra Nagar, Opposite to Amman kovil Saravanampatti<br></br>Coimbatore – 641 035<br></br>
              Ph : +91 9043842020 <br></br> Email: arivomkadaioffical@gmail.com<br></br>
      </td>
      </tr>
    <tr>
    <td colspan="6"  style={{border: '1px solid #ddd', textAlign: 'left', fontSize: '16px',  background: '#3bb77e', padding: '20px', color: '#fff' }}>
    {preorderprocess && preorderprocess.data && preorderprocess.data.preorder === 'preorder' ? (
  <>
    {preorder_ids && preorder_ids.length > 0 ? (
      <>
        Invoice: #000{preorder_ids[0].id}<br />
        Order: {preorder_ids[0].order_id}<br />
        Date: {preorder_ids[0].date}
      </>
    ) : (
      <>
        No preorder_ids found
      </>
    )}
  </>
) : (
  <>
    {order_ids && order_ids.length > 0 ? (
      <>
        Invoice: #000{order_ids[0].id}<br />
        Order: {order_ids[0].order_id}<br />
        Date: {order_ids[0].date}
      </>
    ) : (
      <>
        No order_ids found
      </>
    )}
  </>
)}

       
    </td>
    </tr>
    <tr>
        <th style={{padding: '10px'}}>
        </th>
    </tr>
    <tr>
    <th colSpan={3} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff' }}>
    Billing Address
  </th>
  <th colSpan={3} style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff' }}>
    Shipping Address
  </th>
  
    </tr>	
    
  
        <tr>
        <td colspan="3"  style={{border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>
        {preorderprocess && preorderprocess.data && preorderprocess.data.preorder === 'preorder' ? (
  <>
    {preorder_ids && preorder_ids.length > 0 ? (
      <>
        {preorder_ids[0].customerData[0].name}<br></br>
        {preorder_ids[0].customerData[0].buiulding} {preorder_ids[0].customerData[0].street}<br></br> {preorder_ids[0].customerData[0].city}, {preorder_ids[0].customerData[0].state}, {preorder_ids[0].customerData[0].pincode}<br></br>
        {preorder_ids[0].customerData[0].email}<br></br>
              Ph :  {preorder_ids[0].customerData[0].number}
      </>
    ) : (
      <>
        No preorder_ids found
      </>
    )}
  </>
) : (
  <>
    {order_ids && order_ids.length > 0 ? (
     <>
     {order_ids[0].customerData[0].name}<br></br>
     {order_ids[0].customerData[0].buiulding} {order_ids[0].customerData[0].street}<br></br> {order_ids[0].customerData[0].city}, {order_ids[0].customerData[0].state}, {order_ids[0].customerData[0].pincode}<br></br>
     {order_ids[0].customerData[0].email}<br></br>
           Ph :  {order_ids[0].customerData[0].number}
   </>
    ) : (
      <>
        No order_ids found
      </>
    )}
  </>
)}
         
      </td>
        <td colspan="3"  style={{border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>
        {preorderprocess && preorderprocess.data && preorderprocess.data.preorder === 'preorder' ? (
  <>
    {preorder_ids && preorder_ids.length > 0 ? (
      <>
        {preorder_ids[0].name}<br></br>
        {preorder_ids[0].buiulding} {preorder_ids[0].street}<br></br> {preorder_ids[0].city}, {preorder_ids[0].state}, {preorder_ids[0].pincode}<br></br>
        Landmark :  {preorder_ids[0].landmark}<br></br>
        {preorder_ids[0].email}<br></br>
              Ph :  {preorder_ids[0].number}
      </>
    ) : (
      <>
        No preorder_ids found
      </>
    )}
  </>
) : (
  <>
    {order_ids && order_ids.length > 0 ? (
     <>
     {order_ids[0].name}<br></br>
     {order_ids[0].buiulding} {order_ids[0].street}<br></br> {order_ids[0].city}, {order_ids[0].state}, {order_ids[0].pincode}<br></br>
     Landmark :  {order_ids[0].landmark}<br></br>
     {order_ids[0].email}<br></br>
           Ph :  {order_ids[0].number}
   </>
    ) : (
      <>
        No order_ids found
      </>
    )}
  </>
)}
      </td>
      </tr>
    <tr>
    <th colSpan="3" style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff'}}>Payment Details</th>
        <th colspan="3"  style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff'}}>Shipping Details</th>
      </tr>
    <tr>
        <td colspan="3"  style={{border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>Payment Mode : Online <br></br>
        {preorderprocess && preorderprocess.data && preorderprocess.data.preorder === 'preorder' ? (
  <>
    {preorder_ids && preorder_ids.length > 0 ? (
      <>
       <small>Ref ID : {preorder_ids[0].payment_id}</small>
      </>
    ) : (
      <>
        No preorder_ids found
      </>
    )}
  </>
) : (
  <>
    {order_ids && order_ids.length > 0 ? (
     <>
    <small>Ref ID : {order_ids[0].payment_id}</small>
   </>
    ) : (
      <>
        No order_ids found
      </>
    )}
  </>
)}
        </td>
        <td colspan="3"  style={{border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>Shipping & Handling - Flat Rate <br></br>
        {preorderprocess && preorderprocess.data && preorderprocess.data.preorder === 'preorder' ? (
  <>
    {preorder_ids && preorder_ids.length > 0 ? (
      <>
       (Total Shipping Charges ₹ {preorder_ids[0].shipping}.00)
      </>
    ) : (
      <>
        No preorder_ids found
      </>
    )}
  </>
) : (
  <>
    {order_ids && order_ids.length > 0 ? (
     <>
    (Total Shipping Charges ₹ {order_ids[0].shipping}.00)
   </>
    ) : (
      <>
        No order_ids found
      </>
    )}
  </>
)}
      
        </td>
      </tr>
    <tr>
        <th style={{padding: '10px'}}>
        </th>
    </tr>
    <tr>
        
        <th  style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px',background: '#3bb77e',color:'#fff'}}>Products</th>
        <th width='10%'style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px',background: '#3bb77e',color:'#fff'}}>SKU</th>
        <th width='15%' style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff'}}>Price</th>
        <th width='15%' style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff'}}>Tax</th>
        <th width='5%' style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff'}}>Qty</th>
        <th width='15%' style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px', background: '#3bb77e',color:'#fff'}}>Sub Total</th>
    </tr>
    
    {preorderprocess && preorderprocess.data && preorderprocess.data.preorder === 'preorder'?(<>
          
          {preorderprocess && preorderprocess.data && preorderprocess.data.orderdetails && preorderprocess.data.orderdetails.map(product => (
    <tr key={product.id}>
    <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.product}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.code}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>₹ {Number(product.price/(1+product.gst/100)).toFixed(2)}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>₹ {(product.price-(product.price/(1+product.gst/100))).toFixed(2)} - {product.gst}%</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.quantity}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'right', padding: '10px', fontSize: '15px'}}>₹ {Number(product.subtotal).toFixed(2)}</td>
</tr>
   ))}
    
    
  
    
    <tr>
        <th style={{padding: '10px'}}>
        </th>
    </tr>
    
    <tr>
        <td colspan="5" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Sub Total</td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {subTotal}</td>
    </tr>
    
    <tr>
        <td colspan="5" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Tax <small>IGST-HR 18%</small></td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {gst}</td>
    </tr>
    
    <tr>
        <td colspan="5" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Shipping Charges</td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {preorderprocess && preorderprocess.data && preorderprocess.data.shipping}.00</td>
    </tr>
    
    <tr>
        <th colspan="5" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Grand Total</th>
        <th style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {preorderprocess && preorderprocess.data && preorderprocess.data.grandtotal || 0}.00</th>
    </tr>
    
    <tr>
        <th colspan="6" tyle={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px', minHeight: '30px'}}>Note</th>
        
    </tr>
    </>)
    :
        (<>
        {process && process.data && process.data.orderdetails && process.data.orderdetails.map(product => (
         <tr key={product.id}>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.product}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.code}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>₹ {Number(product.price/(1+product.gst/100)).toFixed(2)}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>₹ {(product.price-(product.price/(1+product.gst/100))).toFixed(2)} - {product.gst}%</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'left', padding: '10px', fontSize: '15px'}}>{product.quantity}</td>
        <td style={{ border: '1px solid #ddd', textAlign: 'right', padding: '10px', fontSize: '15px'}}>₹ {Number(product.subtotal).toFixed(2)}</td>
    </tr>
   ))}
    
    
  
    
    
    
    <tr>
        <td colspan="5" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Sub Total</td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {subTotal}</td>
    </tr>
    
    <tr>
        <td colspan="5" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Tax <small>GST</small></td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {gst}</td>
    </tr>
    
    <tr>
        <td colspan="5" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Shipping Charges</td>
        <td style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹ {process && process.data && process.data.shipping}.00</td>
    </tr>
    
    <tr>
        <th colspan="5" style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>Grand Total</th>
        <th style={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px' }}>₹{process && process.data && process.data.grandtotal || 0}.00</th>
    </tr>
    
    <tr>
        <th colspan="6" tyle={{ border: '0px', textAlign: 'right', padding: '10px', fontSize: '15px', minHeight: '30px'}}>Note</th>
        
    </tr>
       </>
       )}
      </table>

        </div>
      </div>
  </div>


  
  {/* <div class="col-xl-3 col-md-4 col-12 invoice-actions">
    <div class="card">
      <div class="card-body">
        <button class="btn btn-primary d-grid w-100 mb-3" data-bs-toggle="offcanvas" data-bs-target="#sendInvoiceOffcanvas">
          <span class="d-flex align-items-center justify-content-center text-nowrap"><i class="bx bx-paper-plane bx-xs me-1"></i>Send Invoice</span>
        </button>
        <button class="btn btn-label-secondary d-grid w-100 mb-3">
          Download
        </button>
        <a class="btn btn-label-secondary d-grid w-100 mb-3" target="_blank" href="./app-invoice-print.html">
          Print
        </a>
        <a href="./app-invoice-edit.html" class="btn btn-label-secondary d-grid w-100 mb-3">
          Edit Invoice
        </a>
        <button class="btn btn-primary d-grid w-100" data-bs-toggle="offcanvas" data-bs-target="#addPaymentOffcanvas">
          <span class="d-flex align-items-center justify-content-center text-nowrap"><i class="bx bx-dollar bx-xs me-1"></i>Add Payment</span>
        </button>
      </div>
    </div>
  </div> */}

</div>



          </div>

      


              </div>




          
          <Footer />
          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </div>
    <div className="layout-overlay layout-menu-toggle"></div>
  </>
  
  )
}
export default Invoice;
