import React, {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import '../App.css';

ReactSession.setStoreType("localStorage");


 function Aside() {
  const navigate = useNavigate();
  const handleLogout = () => {
   
    ReactSession.set("userId", 0);
   
    navigate('/login');
};

const [isOpen, setIsOpen] = useState(false);
const [isOpens, setIsOpens] = useState(false);
const [ispreorderOpens, setpreorderOpens] = useState(false);
const [isreporOpens, setreportOpens] = useState(false);
const [isreportOpens, setreportsOpens] = useState(false);
const [iscourierOpens, setcourierOpens] = useState(false);
const [isbutton, setIsButton] = useState('');
const [readCount, setOrder] = useState([]);

  const toggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const toggleDropdowns = () => {
    setIsOpens(prevIsOpen => !prevIsOpen);
  };

  const togglereportDropdowns = () => {
    setreportOpens(prevIsOpen => !prevIsOpen);
  };

  const togglereportsDropdowns = () => {
    setreportsOpens(prevIsOpen => !prevIsOpen);
  };
  
  const togglecourierDropdowns = () => {
    setcourierOpens(prevIsOpen => !prevIsOpen);
  };


  const togglepreorderDropdowns = () => {
    setpreorderOpens(prevIsOpen => !prevIsOpen);
  };
  
   const shippingProvider = async () => {
      const response = await axios.get('https://test.arivomkadai.com/api/courier/');
      if(response.data.status === 'success'){
        const provider = response.data.provider;
          setIsButton(provider);
		  //console.log(provider);
      }
    }
    
    useEffect(() => {
      shippingProvider();
    },[]);
	
	
  const manualshipping = async() => {
	  let formData='';
    if(isbutton === 'STCourier'){
		formData = 'Arivom';
	}else{
		formData = 'STCourier';
	}
	//console.log(isbutton);
    try {
     const response = await axios.post('https://test.arivomkadai.com/api/courier/ ', {provider: formData});
    // console.log("orders:", response.data);
	 shippingProvider();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const fetchData = async () => {
    try {
      const response = await axios.get('https://test.arivomkadai.com/api/offlineorders/');
      setOrder(response.data.ReadCount); 
      //console.log("orders aside:", response.data.ReadCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    
        <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
          <div class="app-brand demo">
            <a href="/dashboard" class="app-brand-link">
              
              <span class="app-brand-text demo menu-text fw-bolder ms-2"> <img src="logo.png" alt="logo" width="45%"/></span>
            </a>

            <button class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
              <i class="bx bx-chevron-left bx-sm align-middle"></i>
            </button>
          </div>

          <div class="menu-inner-shadow"></div>


          

          <ul class="menu-inner py-1">
           
            <li class="menu-item">
              <a href="/dashboard" class="menu-link">
                <i class="menu-icon tf-icons bx bx-home-circle"></i>
                <div data-i18n="Analytics">Dashboard</div>
              </a>
            </li>

            <li class="menu-item ">
            <a href="/product" class="menu-link"  >
                <i class="menu-icon tf-icons bx bx-table"></i>
                <div data-i18n="Support">Products</div>
              </a>
            </li>


            <li class="menu-item ">
            <a href="/addvendor" class="menu-link"  >
                <i class="menu-icon tf-icons bx bxs-user-plus"></i>
                <div data-i18n="Support">Add Vendor</div>
              </a>
            </li>

           

            <li className="menu-item">
      <button className="menu-link" onClick={toggleDropdown} style={{border: 'none'}}>
      <i class="menu-icon tf-icons bx bx-world"></i>
       <div data-i18n="Tables">Online</div>
        <i className={`menu-icon tf-icons bx ${isOpen ? 'bx-chevron-up' : 'bx-chevron-down'}`}></i>
       
      </button>
      {isOpen && (
        <ul>
          <li className="menu-item">
            <a href="/online" className="menu-link">
              <i className="menu-icon tf-icons bx bx-table"></i>
              <div data-i18n="Support">Products</div>
            </a>
          </li>
          <li className="menu-item">
            <a href="/onlineorder" className="menu-link">
              <i className="menu-icon tf-icons bx bx-cart"></i>
              <div data-i18n="Support">Orders</div>
            </a>
          </li>
          <li className="menu-item">
            <a href="/scroll" className="menu-link">
              <i className="menu-icon tf-icons bx bx-text"></i>
              <div data-i18n="Support">Scroll Text</div>
            </a>
          </li>
		  <li className="menu-item">
            <a href="/onlinebanner" className="menu-link">
              <i className="menu-icon tf-icons bx bx-square"></i>
              <div data-i18n="Support">banner</div>
            </a>
          </li>
        </ul>
      )}
    </li>



          

            <li class="menu-item">
              <button  class="menu-link" onClick={toggleDropdowns}>
                <i class="menu-icon tf-icons bx bx-store"></i>
                <div data-i18n="Tables">Arivomkadai </div>
                <i className={`menu-icon tf-icons bx ${isOpen ? 'bx-chevron-up' : 'bx-chevron-down'}`}></i>
                {readCount > 0 && <span style={{background: 'red', padding: '5px 10px', color: '#fff', borderRadius: '20px', fontSize: '10px', fontWeight: 'bold'}}>New</span>}
              </button>
              {isOpens && (
             <ul>
             <li class="menu-item">
              <a
                href="/offline"
                class="menu-link"
              >
                <i class="menu-icon tf-icons bx bx-table"></i>
                <div data-i18n="Support">Products</div>
              </a>
            </li>
            <li class="menu-item">
              <a
                href="/offlineorder"
                class="menu-link"
              >
                <i class="menu-icon tf-icons bx bx-cart"></i>
                <div data-i18n="Support">Orders  {readCount > 0 && <span style={{background: 'red', padding: '3px 8px', color: '#fff', borderRadius: '20px', fontSize: '10px', fontWeight: 'bold'}}>{readCount}</span>}</div>
              </a>
            </li>
            <li class="menu-item">
              <a
                href="/offlinescroll"
                class="menu-link"
              >
                <i class="menu-icon tf-icons bx bx-text"></i>
                <div data-i18n="Support">Scroll Text</div>
              </a>
            </li>



            <li class="menu-item">
            <a href="/customer" class="menu-link">
                <i class="menu-icon tf-icons bx bx-user"></i>
                <div data-i18n="Support">Customers</div>
              </a>
            </li>
            <li class="menu-item">
            <a href="/offlinegiftbox" class="menu-link">
                <i class="menu-icon tf-icons bx bx-box"></i>
                <div data-i18n="Support">Gift Box Price</div>
              </a>
            </li>
           
            <li class="menu-item">
              <a
                href="/couponoffline"
                class="menu-link"
              >
                <i class="menu-icon tf-icons bx bx-card"></i>
                <div data-i18n="Support">Coupon</div>
              </a>
            </li>
            <li class="menu-item">
              <a
                href="/deliveryoffline"
                class="menu-link"
              >
                <i class="menu-icon tf-icons bx bx-money"></i>
                <div data-i18n="Support">Delivery Charge</div>
              </a>
            </li>
            <li class="menu-item">
              <a
                href="/stockmaintance"
                class="menu-link"
              >
                <i class="menu-icon tf-icons bx bx-data"></i>
                <div data-i18n="Support">Stock Maintance</div>
              </a>
            </li>
            <li className="menu-item">
            <a href="/offlinebanner" className="menu-link">
              <i className="menu-icon tf-icons bx bx-square"></i>
              <div data-i18n="Support">banner</div>
            </a>
			</li>



             </ul>
              )}
            </li>



            <li class="menu-item">
              <button  class="menu-link" onClick={togglepreorderDropdowns}>
                <i class="menu-icon tf-icons bx bx-time"></i>
                <div data-i18n="Tables">Preorder</div>
                <i className={`menu-icon tf-icons bx ${ispreorderOpens ? 'bx-chevron-up' : 'bx-chevron-down'}`}></i>
       
              </button>
              {ispreorderOpens && (
             <ul>
             <li class="menu-item">
              <a
                href="/preorder"
                class="menu-link"
              >
                <i class="menu-icon tf-icons bx bx-table"></i>
                <div data-i18n="Support">Products</div>
              </a>
            </li>
            <li class="menu-item">
              <a
                href="/preorder_order"
                class="menu-link"
              >
                <i class="menu-icon tf-icons bx bx-cart"></i>
                <div data-i18n="Support">Orders</div>
              </a>
            </li>
           
            <li class="menu-item">
            <a href="/preordergiftbox" class="menu-link">
                <i class="menu-icon tf-icons bx bx-box"></i>
                <div data-i18n="Support">Gift Box</div>
              </a>
            </li>

            <li class="menu-item">
              <a
                href="/couponpreorder"
                class="menu-link"
              >
                <i class="menu-icon tf-icons bx bx-card"></i>
                <div data-i18n="Support">Coupon</div>
              </a>
            </li>
            <li class="menu-item">
              <a
                href="/deliverypreorder"
                class="menu-link"
              >
                <i class="menu-icon tf-icons bx bx-money"></i>
                <div data-i18n="Support">Delivery Charge</div>
              </a>
            </li>
			
			
			 <li className="menu-item">
            <a href="/preorderbanner" className="menu-link">
              <i className="menu-icon tf-icons bx bx-square"></i>
              <div data-i18n="Support">Banner</div>
            </a>
			</li>



             </ul>
              )}
            </li>


            <li class="menu-item">
              <button  class="menu-link" onClick={togglereportDropdowns}>
                <i class="menu-icon tf-icons bx bx-message"></i>
                <div data-i18n="Tables">Report</div>
                <i className={`menu-icon tf-icons bx ${isreporOpens ? 'bx-chevron-up' : 'bx-chevron-down'}`}></i>
       
              </button>
              {isreporOpens && (
             <ul >
            
           

            <li class="menu-item">
            <button  class="menu-link" onClick={togglereportsDropdowns}>
                
                <div data-i18n="Tables">Arivomkadai Report</div>
                <i className={`menu-icon tf-icons bx ${isreportOpens ? 'bx-chevron-up' : 'bx-chevron-down'}`}></i>
       
              </button>
              {isreportOpens && (
                <ul>
                
            <li class="menu-item">
              <a
                href="/reportoffline"
                class="menu-link"
              >
                <div data-i18n="Support">Order Report</div>
              </a>
            </li>
            <li class="menu-item">
              <a
                href="/invoicereport"
                class="menu-link"
              >
                <div data-i18n="Support">Invoice Report</div>
              </a>
            </li>
            <li class="menu-item">
              <a
                href="/itemreport"
                class="menu-link"
              >
                <div data-i18n="Support">Item Report</div>
              </a>
            </li>
            <li class="menu-item">
              <a
                href="/stockreport"
                class="menu-link"
              >
                <div data-i18n="Support">Stock Report</div>
              </a>
            </li>
                 </ul>
                )}
            </li>
            <li class="menu-item">
              <a
                href="/preorderreport"
                class="menu-link"
              >
                
                <div data-i18n="Support">Preorder Report</div>
              </a>
            </li>
            


             </ul>
              )}
            </li>


            
            
			
			

             <li class="menu-item">
              <button  class="menu-link" onClick={togglecourierDropdowns}>
                <i class="menu-icon tf-icons bx bx-message"></i>
                <div data-i18n="Tables">Courier</div>
                <i className={`menu-icon tf-icons bx ${iscourierOpens ? 'bx-chevron-up' : 'bx-chevron-down'}`}></i>
       
              </button>
              {iscourierOpens && (
             <ul>
            
           

            <li class="menu-item">
             <button
      onClick={manualshipping}
      className={` mt-3 me-2  ${isbutton === 'STCourier' ? 'btn btn-primary' : 'btn btn-secondary'}`}
      
    >
	{isbutton === 'STCourier' ? 'ST Courier' : 'Arivom Courier'}
    </button>
            </li>
            
            


             </ul>
              )}
            </li>
			
			<li class="menu-item ">
            <a href="/testinominal" class="menu-link"  >
                <i class="menu-icon tf-icons bx bx-table"></i>
                <div data-i18n="Support">Testimonial</div>
              </a>
            </li>


           
			
			
			 <li class="menu-item">
              <a href="#" class="menu-link" onClick={handleLogout}>
              <i class="bx bx-power-off me-2"></i>
                <div data-i18n="Analytics">Sign Out</div>
              </a>
            </li>
           
           
          </ul>
        </aside>
   
  )
}
export default Aside;