import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
ReactSession.setStoreType("localStorage");

function Editproduct() {
  const userID = ReactSession.get('userId');
  const urlParams = new URLSearchParams(window.location.search);
  const productId = urlParams.get('id');
 // console.log(productId); 
  const [oldData, setOldData] = useState({});
  const [code, setCode] = useState("");
  const [link, setLink] = useState('');
  const [links, setLinks] = useState('');
  const [des, setdes] = useState('');
  const [name, setName] = useState("");
   const [keyword, setKeyword] = useState("");
  const [price, setPrice] = useState('');
  const [rating, setRating] = useState('');
  const [review, setReview] = useState('');
  const [cat, setCat] = useState('');
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [video, setVideo] = useState(null);
  const [error, setError] = useState('');
  const [selectedVideo, setselectVideo] = useState('video');
  
  
     const selectVideo =() =>{
		if(selectedVideo === 'video'){
			setselectVideo('url');
		}else{
			setselectVideo('video');
		}
	};


  const navigate = useNavigate();
   
  if(userID <= 0 ){
    navigate('/login');
 }
  

 
 const openModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
  }
};

const closeModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
  }
};


  useEffect(() => {
    const link = oldData?.data?.[0]?.link;
    const links = oldData?.data?.[0]?.links;
    const des = oldData?.data?.[0]?.content;
    const cat = oldData?.data?.[0]?.cat;
    const name= oldData?.data?.[0]?.product;
    const price = oldData?.data?.[0]?.price;
    const rating= oldData?.data?.[0]?.rating;
    const review = oldData?.data?.[0]?.review;
    const file = oldData?.data?.[0]?.filef;
    const file1 = oldData?.data?.[0]?.file1;
    const file2 = oldData?.data?.[0]?.file2;
    const file3 = oldData?.data?.[0]?.file3;
    const video = oldData?.data?.[0]?.video;
	const product_code = oldData?.data?.[0]?.product_code;
	 const keyword = oldData?.data?.[0]?.keyword;
	

    setName(name);
    setPrice(price);
    setRating(rating);
    setReview(review);
    setFile(file);
    setFile1(file1);
    setFile2(file2);
    setFile3(file3);
    setVideo(video);
    setLink(link);
    setLinks(links);
    setdes(des);
    setCat(cat);
	setCode(product_code);
	 setKeyword(keyword);
  }, [oldData]);



  useEffect(() => {
    const fetchOldData = async () => {
      openModal();
      try {
        
        // Make API call to fetch old data
        const response = await axios.get(`https://test.arivomkadai.com/api/editproduct/?id=${productId}`);
        const oldData = response.data;
        setOldData(oldData); // Set oldData state
        //console.log(oldData);
      } catch (error) {
        console.error('Error fetching old data:', error);
      }
      closeModal();
    };

    // Call the fetchOldData function
    fetchOldData();
  }, []);


    const handleCode = (event) => {
      setCode(event.target.value); 
    };
  const handleNameChange = (event) => {
    setName(event.target.value);
  }
  
  
  const handleKeyword = (event) => {
        setKeyword(event.target.value); // Changed setInput to setLink
    };
	
	
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  }
  const handleLinkChange = (event) => {
    setLink(event.target.value);
  }
  const handleLinkChange1 = (event) => {
    setLinks(event.target.value);
  }
  const handleRatingChange = (event) => {
    setRating(event.target.value);
  }
  const handleReviewChange = (event) => {
    setReview(event.target.value);
  }
  const handleDesChange = (event) => {
    setdes(event.target.value);
	if (typeof window !== 'undefined') {
    ClassicEditor
      .create(document.querySelector('#classic-editor'), {
        // CKEditor configuration here
      })
      .then(editor => {
        editor.model.document.on('change:data', () => {
          const data = editor.getData();
          setdes(data);
        });
      })
      .catch(error => {
        console.error('Error initializing CKEditor:', error);
      });
  }
  }
  const handlecatChange = (event) => {
    setCat(event.target.value);
  }
  const handleUrl = (event) => {
        setVideo(event.target.value); // Changed setInput to setLink
    };

  const handleFileChange = (event) => {
   
    const file = event.target.files[0];
    setFile(file);
  }

  const handleFileChange1 = (event) => {
  
    const file1 = event.target.files[0];
    setFile1(file1);
  }

  const handleFileChange2 = (event) => {
    
    const file2 = event.target.files[0];
    setFile2(file2);
  }

  const handleFileChange3 = (event) => {
  
    const file3 = event.target.files[0];
    setFile3(file3);
  }

  const handleVideoChange = (event) => {
   
    const video = event.target.files[0];
    setVideo(video);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('id', productId);
    if (link !== oldData.link) {
      formData.append('link', link);
    }
  
    // Check if content has changed
    formData.append('link', link);
    formData.append('links', links);
    formData.append('content', des);
    formData.append('images', file);
    formData.append('image1', file1);
    formData.append('image2', file2);
    formData.append('image3', file3);
    formData.append('name', name);
    formData.append('cat', cat);
    formData.append('price', price);
    formData.append('rating', rating);
    formData.append('review', review);
    formData.append('video', video);
    formData.append('code', code);
	formData.append('keyword', keyword);

    try {
      const response = await axios.post('https://test.arivomkadai.com/api/editproduct/', formData);
      //console.log(response.data)
      navigate('/online');
    } catch (error) {
      console.error('There was a problem with login:', error);
      setError('Invalid email or password');
    }
  }

  return (
    <>
    <div id="onloadModal" >
    <div class="modal-content"  align='center'>
        <p style={{padding:'20px'}}>Product Loading.</p>
    </div>
     </div>
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <Aside/>
        <div className="layout-page">
          <Navbar/>
          <div className="content-wrapper">

        



            <div className="container-xxl flex-grow-1 container-p-y">
              <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>Update Product</h4>
              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-4">
                    <h5 className="card-header">Update Product</h5>
                    <div className="card-body">
                      <form id="formAccountSettings" onSubmit={handleSubmit}>
                        <div className="row">
						<div className="mb-3 col-md-3">
                                                        <label htmlFor="link" className="form-label">Product Code</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="code"
                                                            name="code"
                                                            value={code}
                                                            onChange={handleCode}
                                                        />
														</div>
                        <div className="mb-3 col-md-3">
                                                        <label htmlFor="link" className="form-label">Name</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            value={name}
                                                            onChange={handleNameChange}
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="des" className="form-label">Category</label>
                                                       <select
                                                            className="form-select"
                                                           
                                                            name="cat"
                                                            id="cat"
                                                            value={cat}
                                                            onChange={handlecatChange}
                                                        >
                                                        <option value="">Select Category</option>
														 <option value="Tech">Tech</option>
														 <option value="Home">Home</option>
														 <option value="Kitchen">Kitchen</option>
														 <option value="Kids">Kids</option>
														 <option value="Gifts">Gifts</option>
														 <option value="Personalcare">Personalcare</option>
														 <option value="Health">Health</option>
														 <option value="Automobile">Automobile</option>
														 <option value="Fashions">Fashions</option>
														 </select>
                                                    </div>
                          <div className="mb-3 col-md-3">
                            <label htmlFor="firstName" className="form-label">Buy Link</label>
                            <input
                              className="form-control"
                              type="text"
                              id="link"
                              name="link"
                              value={link}
                              onChange={handleLinkChange}
                            />
                          </div>
                          <div className="mb-3 col-md-3">
                            <label htmlFor="firstName" className="form-label">Post Link</label>
                            <input
                              className="form-control"
                              type="text"
                              id="link"
                              name="links"
                              value={links}
                              onChange={handleLinkChange1}
                            />
                          </div>
                         


                          <div className="mb-3 col-md-3">
                                                        <label htmlFor="link" className="form-label">Price</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="price"
                                                            name="price"
                                                            value={price}
                                                            onChange={handlePriceChange}
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="link" className="form-label">Review</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="review"
                                                            name="review"
                                                            value={review}
                                                            onChange={handleReviewChange}
                                                        />
                                                    </div>
                                                  
                                                    <div className="mb-3 col-md-3">
    <label htmlFor="review" className="form-label">Rating</label>
    <select
                className="form-select"
                id="review"
                name="rating"
                value={rating}
                onChange={handleRatingChange}
            >
                
                <option value="">Select Rating</option>
                <option value="3">★★★☆☆ (3 stars)</option>
				<option value="3.0">★★★☆☆ (3.0 stars)</option>
                <option value="3.1">★★★☆☆ (3.1 stars)</option>
                <option value="3.2">★★★☆☆(3.2 stars)</option>
                <option value="3.3">★★★☆☆ (3.3 stars)</option>
                <option value="3.4">★★★☆☆ (3.4 stars)</option>
                <option value="3.5">★★★½☆ (3.5 stars)</option>
                <option value="3.6">★★★½☆ (3.6 stars)</option>
                <option value="3.7">★★★½☆ (3.7 stars)</option>
                <option value="3.8">★★★½☆(3.8 stars)</option>
                <option value="3.9">★★★½☆ (3.9 stars)</option>
                <option value="4">★★★★☆ (4 stars)</option>
				<option value="4.0">★★★★☆ (4.0 stars)</option>
                <option value="4.1">★★★★☆ (4.1 stars)</option>
                <option value="4.2">★★★★☆ (4.2 stars)</option>
                <option value="4.3">★★★★☆ (4.3 stars)</option>
                <option value="4.4">★★★★☆ (4.4 stars)</option>
                <option value="4.5">★★★★½ (4.5 stars)</option>
                <option value="4.6">★★★★½ (4.6 stars)</option>
                <option value="4.7">★★★★½(4.7 stars)</option>
                <option value="4.8">★★★★½ (4.8 stars)</option>
                <option value="4.9">★★★★½ (4.9 stars)</option>
                <option value="5.0">★★★★★ (5.0 stars)</option>

            </select>
    </div>
                                                 
                          <div className="mb-3 col-md-6">
                            <label htmlFor="lastName" className="form-label">Image</label>
                            <input
                              className="form-control"
                              type="file"
                              name="images"
                              id="images"
                              accept="image/png, image/jpeg, image/jpg, image/pdf"
                              onChange={handleFileChange}
                            />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label htmlFor="lastName" className="form-label">Image</label>
                            <input
                              className="form-control"
                              type="file"
                              name="image1"
                              id="image1"
                              accept="image/png, image/jpeg, image/jpg, image/pdf"
                              onChange={handleFileChange1}
                            />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label htmlFor="lastName" className="form-label">Image</label>
                            <input
                              className="form-control"
                              type="file"
                              name="image2"
                              id="image2"
                              accept="image/png, image/jpeg, image/jpg, image/pdf"
                              onChange={handleFileChange2}
                            />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label htmlFor="lastName" className="form-label">Image</label>
                            <input
                              className="form-control"
                              type="file"
                              name="image3"
                              id="image3"
                              accept="image/png, image/jpeg, image/jpg, image/pdf"
                              onChange={handleFileChange3}
                            />
                          </div>

                         <div className="mb-3 mt-4 col-md-4">
													   <button type="button"  className={selectedVideo === 'video' ? 'btn btn-primary me-2' : 'btn btn-secondary me-2'} onClick={selectVideo}>Video File</button>
													   <button type="button" className={selectedVideo === 'video' ? 'btn btn-secondary me-2' : 'btn btn-primary me-2'} onClick={selectVideo}>YouTube Video</button>
                                                    </div>
													
							{selectedVideo === 'video' ? (						
                            <div className="mb-3 col-md-8">
                                                          <label htmlFor="des" className="form-label">Video</label>
                                                          <input
                                                              className="form-control"
                                                              type="file"
                                                              name="video"
                                                              id="video"
                                                              accept="mp4"
                                                              onChange={handleVideoChange}
                                                          />
                                                      </div>
													  ):(
													
													<div className="mb-3 col-md-8">
                                                        <label htmlFor="des" className="form-label">Video Url</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="url"
                                                            id="url"
															 value={video}
                                                            onChange={handleUrl}
                                                            
                                                        />
                                                    </div>
													)}
													
													 <div className="mb-3 col-md-12">
                            <label htmlFor="lastName" className="form-label">Description</label>
                            <textarea
                              className="form-control"
							  id="classic-editor"
                              type="text"
                              name="des"
                              value={des}
                              onChange={handleDesChange}
                            />
                          </div>
						  
						  
						  {/* <div className="mb-3 col-md-12">
                                                        <label htmlFor="des" className="form-label">Keywords</label>
                                                        <textarea
                                                            className="form-control"
                                                            type="text"
                                                            name="keyword"
                                                            id="keyword"
                                                            value={keyword}
                                                            onChange={handleKeyword}
                                                        />
                                                    </div> */}
  
                        </div>
                        <div className="mt-2">
                          <button type="submit" className="btn btn-primary me-2">Save changes</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer/>
          <div className="content-backdrop fade"></div>
        </div>
      </div>
      <div className="layout-overlay layout-menu-toggle"></div>
    </div>
    </>
  );
}

export default Editproduct;
