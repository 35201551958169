import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

 function Order_details() {
    const userID = ReactSession.get('userId');
    const [order, setOrder]=useState(null);
    const [status, setStatus]=useState('');
    const [process, setProcess] = useState([]);
    const queryParameters = new URLSearchParams(window.location.search)
    const orderId = queryParameters.get("id");
    const [currentOrderId, setCurrentOrderId] = useState(orderId);
    const [currentOrder, setCurrentOrder] = useState(null);
    const [prevOrderId, setPrevOrderId] = useState(null);
    const [nextOrderId, setNextOrderId] = useState(null);
    const [orders, setOrders] = useState([]);
    const [readStaus, setRead] = useState(false);
    const navigate = useNavigate();

    if(userID <= 0 ){
        navigate('/login');
     }

    const updatestatus = async () => {
        const formData = {
            id:orderId,
            status: status
        }
        //console.log(formData);
        try {
            // Make API call to verify OTP
            const response = await axios.post('https://test.arivomkadai.com/api/order_status/', formData);
           console.log(response.data);
           if(response.data.status === 'success'){
              alert('Order Status Updated');
           }
        } catch (error) {
            console.error('Status Error:', error);
        }
    };
 
    const fetchData = async () => {
   
    // console.log("user:",formData);
      try {
        const formData = {id:orderId};
        const response = await axios.post('https://test.arivomkadai.com/api/processsucess/' , formData);
       setProcess(response.data);
     //console.log("orders:", response.data);
      } catch (error) { 
        console.error("Error fetching data:", error);
      }
    };

    useEffect(() => {
      fetchData();
    }, []);

    const fetchDatas = async () => {
      try {
        const response = await axios.get('https://test.arivomkadai.com/api/offlineorders/');
        setOrder(response.data);
        setOrders(response.data?.data || []);
       // console.log("orders:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    useEffect(() => {
      fetchDatas();
    }, []);

    const order_ids = order?.data?.filter(item => item.order_id === orderId) || [];
    const filteredOrders = orders.filter(order => order.order_id === orderId);

    useEffect(() => {
        const index = orders.findIndex(order => order.order_id === currentOrderId);
        if (index >= 0) {
          const prevIndex = index - 1;
          const nextIndex = index + 1;
    
          const currentOrder = orders[index];
          const prevOrder = orders[prevIndex] || null;
          const nextOrder = orders[nextIndex] || null;
    
          setCurrentOrder(currentOrder);
          setPrevOrderId(prevOrder ? prevOrder.order_id : null);
          setNextOrderId(nextOrder ? nextOrder.order_id : null);
        }
    }, [currentOrderId, orders]);

  
  // Handle previous button click
    const handlePrev = () => {
      if (prevOrderId) {
        navigate(`?id=${prevOrderId}`); // Navigate to the previous order
        setCurrentOrderId(prevOrderId); // Update state
      }
    };

    const handleNext = () => {
      if (nextOrderId) {
        navigate(`?id=${nextOrderId}`); // Navigate to the next order
        setCurrentOrderId(nextOrderId); // Update state
      }
    };
 

 
 
    const stCourier = async () => {
  
    const formData = new FormData();
    formData.append('order_id', orderId);
    formData.append('order_from', 'offline');

    const formDatas = {
      order_id: orderId,
      order_from: 'offline'
    }
 
    try {
      const response = await axios.post('https://test.arivomkadai.com/api/stcourier/', formData); 
      if(response.data.id){
        alert(response.data.id);
      }else{
        alert('Waiting for response!');
      }
      fetchData();
      
    } catch (error) {
      console.error('Status Error:', error);
    }
    fetchData();
  };
	 
  useEffect(() => {
    if(order_ids && order_ids[0]?.read=== 'yes' && readStaus === false){
      updateRead();
    }
  },[order, order_ids]);

  const updateRead = async () => {
    try {
      const response = await axios.post('https://test.arivomkadai.com/api/read-order/', {orderId : orderId}); 
      if(response.data.status === 'success'){
        setRead(true);
      }
      //console.log(response.data);
    } catch (error) {
      console.error('Status Error:', error);
    }
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
    <div className="layout-container">
      <Aside />
      <div className="layout-page">
        <Navbar />
        <div className="content-wrapper">
    
        <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row">
        <div class="col-lg-8 mb-4 order-0">
                
    <div class="card mb-4">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="card-title m-0">Cart Details</h5>
        
      </div>
      <div class="card-datatable table-responsive">
        <table class="datatables-order-details table">
          <thead>
            <tr>
              <th></th>
              <th class="w-50">products</th>
              <th class="w-25">price</th>
              <th class="w-25">qty</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
          {process && process.data && process.data.orderdetails && process.data.orderdetails.map(product => (
        
        <tr class="odd">
          <td class="  control" tabindex="0" style={{display: 'none'}} key={product.id}></td >
          <td class="sorting_1">
              <div class="d-flex justify-content-start align-items-center text-nowrap">
                <div class="avatar-wrapper">
                  <div class="avatar me-2">
                    <img src={`https://test.arivomkadai.com/api/product_data/${product.img}`} alt={product.product} class="rounded-2"/>
                  </div>
                </div>
              </div>
            </td>
            <td><span>{product.product}</span><br></br><p class="text-muted">{product.code}<br></br>{product.varient}</p></td>
            <td><span>₹ {Number(product.price).toFixed(2)}</span></td>
            <td><span class="text-body">{product.quantity}</span></td>
            <td><h6 class="mb-0">₹ {Number(product.subtotal).toFixed(2)}</h6></td>
        </tr>
            ))}
          </tbody>
        </table>
        <div class="d-flex justify-content-end align-items-center m-3 mb-2 p-1">
          <div class="order-calculations">
            <div class="d-flex justify-content-between mb-2">
              <span class="w-px-100">Sub Total : </span>
              <span class="text-heading">₹ {Number(process && process.data && process.data.total).toFixed(2) || 0.00}</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="w-px-100">Discount : </span>
              <span class="text-heading mb-0">- ₹ {Number(process && process.data && process.data.offer).toFixed(2) || 0.00}</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="w-px-100">Shipping : </span>
              <span class="text-heading mb-0">₹ {Number(process && process.data && process.data.shipping).toFixed(2) || 0.00}</span>
            </div>
            <div class="d-flex justify-content-between">
              <h6 class="w-px-100 mb-0"><b>Grand Total : </b></h6>
              <h6 class="mb-0"><b>₹ {Number(process && process.data && process.data.grandtotal).toFixed(2) || 0.00}</b></h6>
            </div>
          </div>
        </div>
      </div>
    </div>
   
              </div>
              
              <div class="col-md-6 col-lg-4 col-xl-4 order-0 mb-4">
             
                <div class="card pb-4">
                
                  <div class="card-header d-flex align-items-center justify-content-between pb-0">
                 
                    <div class="card-title mb-0">
                      <h4 class="m-0 me-2 mb-4">Order Details</h4>
                      
                    </div>
                    
                  </div>
                  {order_ids && order_ids.length > 0 ? (
				  
                  <div class="card-body">
        <div class="d-flex justify-content-start align-items-center mb-4">
          <div class="avatar me-2">
            <img src={`https://test.arivomkadai.com/api/customer_data/${order_ids[0].customerData[0].profile}`} alt="Avatar" class="rounded-circle"/>
          </div>
          <div class="d-flex flex-column">
            <a href="app-user-view-account.html" class="text-body text-nowrap">
              <h5 class="mb-0">{order_ids[0].customerData[0].name}</h5>
              <small class="text-muted">Order ID: {order_ids[0].order_id}</small>
            </a>
          </div>
        </div>
        <div class="d-flex justify-content-start align-items-center mb-4">
          <span class="avatar rounded-circle bg-label-success me-2 d-flex align-items-center justify-content-center"><i class="bx bx-cart-alt bx-sm lh-sm"></i></span>
          <h6 class="text-body text-nowrap mb-0">{order_ids[0].count} Items</h6>
        </div>
        <div class="d-flex justify-content-between">
          <h6>Shipping Details</h6>
           </div>
        <p class=" mb-2"><b>{order_ids[0].name}</b></p>
        <p class=" mb-1"> {order_ids[0].buiulding}, {order_ids[0].street}, {order_ids[0].city}, {order_ids[0].state} - {order_ids[0].pincode}</p>
        <p class=" mb-4">{order_ids[0].landmark}</p>
		   
        <p class=" mb-1"><i class="fa fa-envelope"></i> {order_ids[0].email}</p>
        <p class=" mb-1"><i class="fa fa-phone"></i> {order_ids[0].number}</p>

        <h5 className="card-header">Status</h5>
                                        <div className="card-body">
                                                <div className="row">
                                                    <div className="mb-3 col-md-12 col-xl-12 col-lg-12">
                                                      <label htmlFor="link" className="form-label">Order Status</label>
																		                  <select class="form-control" name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
																			                  <option selected hidden >{order_ids[0].status}</option> 
																			                  <option value="Confirm">Confirm</option> 
																			                  <option value="Dispatched">Shipped</option> 
																			                  <option value="Delivered">Delivered</option> 
																			                  <option value="Cancelled">Cancelled</option> 
																			                </select>
                                                    </div>
													
                                                
												
                                                <div className="mt-2 col-md-6 col-xl-6 col-lg-6 col-xxl-6 col-6">
                                                  <button onClick={updatestatus} className="btn btn-primary me-2 w-100">Update</button>
                                                </div>    
												                        <div className="mt-2 col-md-6 col-xl-6 col-lg-6 col-xxl-6 col-6">
	                                                <a href={`invoice?id=${order_ids[0].order_id}`} class="btn btn-warning w-100">Invoice</a>
                                                </div>
                                                <div className="mt-2 col-md-12 col-xl-12 col-lg-12 col-xxl-12 col-12">
                                                  <a href={`label?id=${order_ids[0].order_id}`} target="_blank" class="btn btn-success w-100 text-white">Print Label</a>
                                                </div>
                                                <div className="mt-2 col-md-12 col-xl-12 col-lg-12 col-xxl-12 col-12">
                                                  {process && process.data && process.data.courier === '' ? (
	                                                  <button  class="btn btn-info mt-2 w-100" onClick={stCourier}>Create ST Courier Shipping</button>
                                                  ):(<p class="mt-3">Courier : {process && process.data && process.data.courier}<br></br>AWNO : {process && process.data && process.data.awno}</p>)}
									                       	      </div>
                                          </div>
                                        </div>

                                        <div>
          {/* <p>Previous Order ID: {prevOrderId}</p>
          <p>Next Order ID: {nextOrderId}</p> */}
        </div>                     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      
      
      <button
        className="btn btn-outline-info mt-2 text-sm"
        style={{ borderRadius: '50%', height: '40px', width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        onClick={handlePrev}
        disabled={!prevOrderId} // Disable if at the first item
      >
        <i className="bx bx-left-arrow-alt bx-sm"></i>
      </button>
      {/* <span style={{ fontSize: '18px' }}>{currentOrder?.order_id}</span> */}
      <button
        className="btn btn-outline-info mt-2 text-sm"
        style={{ borderRadius: '50%', height: '40px', width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        onClick={handleNext}
        disabled={!nextOrderId} // Disable if at the last item
      >
        <i className="bx bx-right-arrow-alt bx-sm"></i>
      </button>
    </div>

      
      
      </div>
      ) : (
        <>
          No order_ids found
        </>
      )}
        
                </div>
            
              </div>
              </div>


              </div>




          
          <Footer />
          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </div>
    <div className="layout-overlay layout-menu-toggle"></div>
  </div>
  )
}
export default Order_details;
