import React, { useState } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
ReactSession.setStoreType("localStorage");


function Addoflineproduct() {
    const userID = ReactSession.get('userId');
    const [name, setName] = useState("");
	const [code, setCode] = useState("");
    const [price, setPrice] = useState("");
	const [keyword, setKeyword] = useState("");
    const [file, setFile] = useState(null); 
    const [file1, setFile1] = useState(null); 
    const [file2, setFile2] = useState(null); 
    const [file3, setFile3] = useState(null); 
    const [video, setVideo] = useState(null); // Changed empty string to null for file state
    const [des, setDes] = useState("");
    const [weight, setWeight] = useState("");
    const [height, setHeight] = useState("");
    const [width, setWidth] = useState("");
    const [breadth, setBreadth] = useState("");
    const navigate = useNavigate();
    const [error, setError] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [productCode, setProductCode] = useState(true);
	const [selectedVideo, setselectVideo] = useState('video');
    if(userID <= 0 ){
        navigate('/login');
     }


      const selectVideo =() =>{
		if(selectedVideo === 'video'){
			setselectVideo('url');
		}else{
			setselectVideo('video');
		}
	};

     
 const openModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
        document.body.classList.add('modal-open');
    }
  };
  
  const closeModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
    }
  };
  
    const handleCode = async (event) => {
    const code = event.target.value;
    setCode(code); 
    
    if(code === '' || code === null){
	  setErrorMessage('Please enter a valid Product code');
	}else{
		
        const response = await axios.put('https://test.arivomkadai.com/api/addpreorderprouct/', { proCode: code });
		const results = response.data;
        const result = response.data.status;
		// console.log(results);
      // console.log(result);
		if(result === 'error'){
			const errorMessage = response.data.nextId;
            setErrorMessage(errorMessage);
		}else{
			setErrorMessage('');
		} 
	}
};

	
		const getProductID =async()=>{

		const response = await axios.get('https://test.arivomkadai.com/api/addpreorderprouct/');
		const results = response.data;
        const result = response.data.nextId;
		setCode('P00'+result);
		//console.log(result);
		};
		
		
		
		if(productCode === true) {
		  getProductID();
		  setProductCode(false);
	    }


    const handleName = (event) => {
        setName(event.target.value); // Changed setInput to setLink
    };
	 

    const handlePrice = (event) => {
        setPrice(event.target.value); // Changed setInput to setLink
    };
	
	const handleKeyword = (event) => {
        setKeyword(event.target.value); // Changed setInput to setLink
    };

  
    const handleVideo = (event) => {
        setVideo(event.target.files[0]); // Changed setInput to setLink
    };

 
    
    const handleDes = (event) => {
        setDes(event.target.value);
		if (typeof window !== 'undefined') {
    ClassicEditor
      .create(document.querySelector('#classic-editor'), {
        // CKEditor configuration here
      })
      .then(editor => {
        editor.model.document.on('change:data', () => {
          const data = editor.getData();
          setDes(data);
        });
      })
      .catch(error => {
        console.error('Error initializing CKEditor:', error);
      });
  }
    };
	
	 const handleUrl = (event) => {
        setVideo(event.target.value); // Changed setInput to setLink
    };


    const handleweight = (event) => {
        setWeight(event.target.value);
    };

    const handleheight = (event) => {
        setHeight(event.target.value);
    };


    const handlebreadth = (event) => {
        setBreadth(event.target.value);
    };

    const handlewidth = (event) => {
        setWidth(event.target.value);
    };
    
    const handleFile = (event) => {
        setFile(event.target.files[0]); // Only taking the first file if multiple files are selected
    };

    const handleFile1 = (event) => {
        setFile1(event.target.files[0]); // Only taking the first file if multiple files are selected
    };

    const handleFile2 = (event) => {
        setFile2(event.target.files[0]); // Only taking the first file if multiple files are selected
    };
    const handleFile3 = (event) => {
        setFile3(event.target.files[0]); // Only taking the first file if multiple files are selected
    };
    
    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('content', des);
        formData.append('image', file);
        formData.append('image1', file1);
        formData.append('image2', file2);
        formData.append('image3', file3);
        formData.append('name', name);
        formData.append('price', price);
        formData.append('height', height);
        formData.append('width', width);
        formData.append('breadth', breadth);
        formData.append('weight', weight);
        formData.append('video', video);
		 formData.append('code', code);
		 formData.append('keyword', keyword);
        
         //console.log(formData);
         openModal();
        try {
           
            const response = await axios.post('https://test.arivomkadai.com/api/addpreorderprouct/', formData);
            //console.log( response.data);
            // Redirecting to home page
    navigate('/preorder');
        } catch (error) {
            // Handle error
            console.error('There was a problem with login:', error);
            setError('Invalid email or password');
        }
        closeModal();
    };

    return (


        <>
        <div id="onloadModal" >
        <div class="modal-content"  align='center'>
            <p style={{padding:'20px'}}>Product Loading.</p>
        </div>
         </div>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Aside/>
                <div className="layout-page">
                    <Navbar/>
                    <div className="content-wrapper">


                   




                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span> Add Product</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        <h5 className="card-header">Add New Product</h5>
                                        <div className="card-body">
                                            <form id="formAccountSettings"  onSubmit={handleSubmit}>
											
											 <div className="row">
												    <div className="mb-3 col-md-3">
                                                        <label htmlFor="link" className="form-label">Product Code</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="code"
                                                            name="code"
                                                            value={code}
                                                            onChange={handleCode}
                                                        />
														 {errorMessage && errorMessage.length > 0 && (
                                              <p className="text-danger">{errorMessage}</p>
                                            )}
                                                    </div>
                                               
                                                    <div className="mb-3 col-md-9">
                                                        <label htmlFor="link" className="form-label">Name</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            value={name}
                                                            onChange={handleName}
                                                        />
                                                    </div>
													
                                                  
                                                   
                                                   

                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="link" className="form-label">Price</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="price"
                                                            name="price"
                                                            value={price}
                                                            onChange={handlePrice}
                                                        />
                                                    </div>

                                                   

                                                    

                                                     <div className="mb-3 mt-4 col-md-4">
													   <button type="button"  className={selectedVideo === 'video' ? 'btn btn-primary me-2' : 'btn btn-secondary me-2'} onClick={selectVideo}>Video File</button>
													   <button type="button" className={selectedVideo === 'video' ? 'btn btn-secondary me-2' : 'btn btn-primary me-2'} onClick={selectVideo}>YouTube Video</button>
                                                    </div>
													
													{selectedVideo === 'video' ? (
                                                    <div className="mb-3 col-md-4">
                                                        <label htmlFor="des" className="form-label">Video</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="video"
                                                            id="video"
                                                            accept="mp4"
                                                            onChange={handleVideo}
                                                        />
                                                    </div>
													
													):(
													
													<div className="mb-3 col-md-4">
                                                        <label htmlFor="des" className="form-label">Video Url</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="url"
                                                            id="url"
															 value={video}
                                                            onChange={handleUrl}
                                                        />
                                                    </div>
													
													)}
                                                   
                                                   

                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="image" className="form-label">Image</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="image"
                                                            id="image"
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            onChange={handleFile}
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="image" className="form-label">Image</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="image1"
                                                            id="image1"
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            onChange={handleFile1}
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="image" className="form-label">Image</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="image2"
                                                            id="image2"
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            onChange={handleFile2}
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="image" className="form-label">Image</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="image3"
                                                            id="image3"
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            onChange={handleFile3}
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-md-12">
                                                        <label htmlFor="des" className="form-label">Description</label>
                                                        <textarea
                                                            className="form-control"
                                                            type="text"
                                                            name="des"
                                                            id="classic-editor"
                                                            value={des}
                                                            rows="3"
                                                            onChange={handleDes}
                                                        />
                                                    </div>
													
													
													{/* <div className="mb-3 col-md-12">
                                                        <label htmlFor="des" className="form-label">Keywords</label>
                                                        <textarea
                                                            className="form-control"
                                                            type="text"
                                                            name="keyword"
                                                            id="keyword"
                                                            value={keyword}
                                                            onChange={handleKeyword}
                                                        />
                                                    </div> */}
													
													<h5 className="card-header">Shipping Details Update</h5>
													
													<div className="mb-3 col-md-3">
                                                        <label htmlFor="des" className="form-label">Breadth</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="breadth"
                                                            id="breadth"
                                                            value={breadth}
                                                            onChange={handlebreadth}
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="des" className="form-label">Width</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="width"
                                                            id="width"
                                                            value={width}
                                                            onChange={handlewidth}
                                                        />
                                                    </div>


                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="des" className="form-label">Height</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="height"
                                                            id="height"
                                                            value={height}
                                                            onChange={handleheight}
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="des" className="form-label">Weight</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="weight"
                                                            id="weight"
                                                            value={weight}
                                                            onChange={handleweight}
                                                        />
                                                    </div>
													
													
													
													
                                                   
                                                </div>
                                                <div className="mt-2" align="center"> 
                                                    <button type="submit" className="btn btn-primary me-2">Add</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
        </div>
        </>
  )
}
export default  Addoflineproduct;