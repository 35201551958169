import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");


function Deliveryoffline() {
    const userID = ReactSession.get('userId');
    const navigate = useNavigate();
    const [delivery, setdelivery] = useState("");
    const [value, setValue] = useState("");
    const [value1, setValue1] = useState("");
    const [below, setBelow] = useState("");
    const [above, setAbove] = useState("");
    const [above1, setAbove1] = useState("");
    


    useEffect(() => {
        const value = delivery?.data?.[0]?.value;
        const value1 = delivery?.data?.[0]?.value1;
        const below= delivery?.data?.[0]?.below;
        const above = delivery?.data?.[0]?.above;
        const above1 = delivery?.data?.[0]?.above1;
       
    
        setValue(value);
        setValue1(value1);
        setBelow(below);
        setAbove(above);
        setAbove1(above1);
       
      }, [delivery]);
    
    if(userID <= 0 ){
        navigate('/login');
     }

      useEffect(() => {
        const fetchOldData = async () => {
          try {
            // Make API call to fetch old data
            const response = await axios.get(`https://test.arivomkadai.com/api/deliveryoffline/`);
            setdelivery(response.data); 
           // console.log(response.data);
          } catch (error) {
            console.error('Error fetching old data:', error);
          }
        };
    
        // Call the fetchOldData function
        fetchOldData();
      }, []);

      
    const handleValue = (event) => {
        setValue(event.target.value);
    }
    const handleValue1 = (event) => {
        setValue1(event.target.value);
    }
    const handleBelow = (event) => {
        setBelow(event.target.value);
    }
    const handleAbove = (event) => {
        setAbove(event.target.value);
    }
    const handleAbove1 = (event) => {
        setAbove1(event.target.value); // Changed setInput to setLink
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = { 
            value: value,
            value1: value1,
            above:above,
            above1:above1,
            below:below
         };
       // console.log(formData);
        try {
            const response = await axios.post('https://test.arivomkadai.com/api/deliveryoffline/', formData);
           // console.log( response.data);
            alert("update sucessfully");
            // Redirecting to home page
             //navigate('/product');
        } catch (error) {
            // Handle error
            console.error('There was a problem with login:', error);
        }
    };
  return (
    <div className="layout-wrapper layout-content-navbar">
    <div className="layout-container">
        <Aside/>
        <div className="layout-page">
            <Navbar/>
            <div className="content-wrapper">
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                <h5 className="card-header">Delivery Charges</h5>
                                <div className="card-body">
                                   
                                        <div className="row">
                                            <div className="mb-3 col-md-3 col-xl-6 col-lg-6">
                                                <label htmlFor="link" className="form-label">cart value</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    id="value"
                                                    name="value"
                                                    value={value}
                                                    onChange={handleValue}
                                                />
                                            </div>

                                            <div className="mb-3 col-md-3 col-xl-6 col-lg-6">
                                                <label htmlFor="link" className="form-label">cart value1</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    id="value1"
                                                    name="value1"
                                                    value={value1}
                                                    onChange={handleValue1}
                                                />
                                            </div>



                                            <div className="mb-3 col-md-3 col-xl-6 col-lg-6">
                                                <label htmlFor="link" className="form-label">0 to {value}</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    id="below"
                                                    name="below"
                                                    value={below}
                                                    onChange={handleBelow}
                                                />
                                            </div>
                                            <div className="mb-3 col-md-3 col-xl-6 col-lg-6">
                                                <label htmlFor="link" className="form-label">{value} to {value1}</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    id="above"
                                                    name="above"
                                                    value={above}
                                                    onChange={handleAbove}
                                                />
                                            </div>


                                            <div className="mb-3 col-md-3 col-xl-6 col-lg-6">
                                                <label htmlFor="link" className="form-label">above {value1}</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    id="above1"
                                                    name="above1"
                                                    value={above1}
                                                    onChange={handleAbove1}
                                                />
                                            </div>
                                            
                                        </div>
                                        <div className="mt-2">
                                            <button type="submit"onClick={handleSubmit} className="btn btn-primary me-2">Edit</button>
                                        </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
                <div className="content-backdrop fade"></div>
            </div>
        </div>
    </div>
    <div className="layout-overlay layout-menu-toggle"></div>
</div>
  )
}
export default Deliveryoffline;
